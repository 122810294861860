import React, { useMemo } from "react";
import { Link } from "@mui/material";

//shared
import { formatDate } from "shared/libs/date";
import { routesMap } from "shared/config";
import { formatPhone } from "shared/libs/string";
import { formatCost } from "shared/libs/number";
import { Order, RealizationMethodType } from "shared/api";
import { OrderStatusLabel, Table } from "shared/ui";

interface Props {
  orders?: Order[];
  totalCount?: number;
  loading: boolean;
  withUser?: boolean;
}

export const Orders: React.FunctionComponent<Props> = ({
  orders: providedOrders = [],
  totalCount = 0,
  loading,
  withUser = true,
}) => {
  const orders = useMemo(
    () => providedOrders?.sort((a, b) => b.id - a.id),
    [providedOrders]
  );
  return (
    <Table
      limit={totalCount || 0}
      data={orders}
      totalCount={totalCount || 0}
      loading={loading}
      withPagination={false}
      headers={[
        {
          title: "Дата",
          extract: (item: Order) => formatDate(item.created_at),
        },
        {
          title: "ID",
          extract: (item: Order) => (
            <Link href={routesMap.order.getRoute(item.id)} target="_blank">
              {item.id}
            </Link>
          ),
        },
        withUser
          ? {
              title: "Телефон",
              extract: (item: Order) => (
                <Link href={`tel:+7${item.user.phone}`} noWrap>
                  {formatPhone(`7${item.user.phone}`)}
                </Link>
              ),
            }
          : null,
        withUser
          ? {
              title: "Клиент",
              extract: (item: Order) => (
                <Link href={routesMap.user.getRoute(item.user.id)}>
                  {item.user.full_name || item.user.email || item.user.phone}
                </Link>
              ),
            }
          : null,
        {
          title: "Статус",
          extract: (item: Order) => <OrderStatusLabel status={item.state} />,
        },
        {
          title: "Тип реализации",
          extract: (item: Order) =>
            item.realization_method?.type &&
            RealizationMethodType[
              item.realization_method
                .type as unknown as keyof typeof RealizationMethodType
            ],
        },
        {
          title: "Адрес",
          extract: (item: Order) =>
            item.realization_method?.address?.line ||
            item.realization_method?.pick_up_point?.address.line,
        },
        {
          title: "Сумма",
          align: "right",
          extract: (item: Order) => <b>{formatCost(item.cost, 1)} ₽</b>,
        },
      ]}
    />
  );
};
