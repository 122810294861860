import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import {
  addWarehouseImageRequest,
  useGetWarehousImageTypes,
} from "entities/warehouse";

import { Warehouse, WarehouseImage } from "shared/api";
import { deleteWarehouseImageRequest } from "entities/warehouse/api";
import { WithConfirm } from "shared/ui";

interface Props {
  warehouse: Warehouse;
  afterSave: () => any;
}

export const Images: React.FunctionComponent<Props> = ({
  warehouse,
  afterSave,
}) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<null | string>(null);
  const [images, setImages] = useState<any>(null);

  const { data: types } = useGetWarehousImageTypes();

  useEffect(() => {
    if (types?.length) {
      setType(types[0]);
    }
  }, [types]);

  const handleSave = async () => {
    if (!images || !type) return null;

    const formData = new FormData();

    Array.from(images).forEach((image: any, index: any) => {
      formData.append(`images[${index}]`, image);
    });

    formData.append("type", type);

    await addWarehouseImageRequest(warehouse.id, formData);

    setOpen(false);
    afterSave();
  };

  const handleAdd = (event: React.ChangeEvent<HTMLInputElement>) =>
    setImages(event?.target?.files);

  const handleDelete = async (image: WarehouseImage) => {
    await deleteWarehouseImageRequest({
      warehouseId: warehouse.id,
      imageId: image.id,
    });

    afterSave();
  };

  return (
    <div>
      <Box>
        <Tabs value={type} onChange={(e, value) => setType(value)}>
          {Boolean(type) &&
            types?.map((type) => (
              <Tab
                key={type}
                value={type}
                label={`${type} (${
                  warehouse?.warehouse_images?.filter(
                    (image) => image.type === type
                  )?.length
                })`}
              />
            ))}
        </Tabs>
        <Box display="flex" flexWrap="wrap" mt={2}>
          {warehouse?.warehouse_images
            ?.filter((image) => image.type === type)
            ?.map((image) => (
              <Paper key={image.id} sx={{ mr: 2, mb: 2 }}>
                <Box display="flex" flexDirection="column" p={1}>
                  <Box>
                    <a target="_blank" href={image.origin_path}>
                      <img
                        src={image.origin_path}
                        style={{
                          display: "block",
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                        }}
                      />
                    </a>
                  </Box>
                  <Box mt={0.5} textAlign="center">
                    <WithConfirm
                      onConfirm={() => handleDelete(image)}
                      confirmTitle="Удалить"
                      color="error"
                    >
                      <Button color="error">Удалить</Button>
                    </WithConfirm>
                  </Box>
                </Box>
              </Paper>
            ))}
        </Box>
      </Box>
      <Dialog open={open}>
        <DialogContent>
          <Box>
            <input type="file" onChange={handleAdd} multiple />
          </Box>
          <Box mt={4}>
            <FormControl fullWidth>
              <InputLabel id="type">Тип</InputLabel>
              <Select
                labelId="type"
                value={type}
                onChange={(e) => setType(e.target?.value)}
                label="Тип"
              >
                {types?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Добавить
        </Button>
      </Box>
    </div>
  );
};
