import {
  RequestHookParams,
  useRequest,
  Warehouse,
  ResponseWithMeta,
  request,
  WarehouseSchedule,
} from "shared/api";
import { apiRoutesMap } from "shared/config";

export const useGetWarehouses = () =>
  useRequest<ResponseWithMeta<Warehouse[]>>(apiRoutesMap.warehouses.getRoute());

export const useGetWarehouse = ({
  variables = {},
  ...params
}: RequestHookParams<{ id?: string | number }>) =>
  useRequest<Warehouse>(apiRoutesMap.warehouse.getRoute(variables));

export const useGetWarehousImageTypes = ({
  variables,
  ...params
}: RequestHookParams<never> = {}) =>
  useRequest<string[]>(apiRoutesMap.warehouseImageTypes.getRoute(), params);

export const changeWarehouseRequest = (warehouse: Warehouse) =>
  request.put(apiRoutesMap.warehouse.getRoute({ id: warehouse.id }), warehouse);

export const changeWarehouseScheduleRequest = (
  id: number | string,
  schedule: WarehouseSchedule
) => request.put(apiRoutesMap.warehouseSchedule.getRoute({ id }), schedule);

export const addWarehouseImageRequest = (
  id: number | string,
  image: FormData
) => request.post(apiRoutesMap.warehouseImages.getRoute({ id }), image);

export const deleteWarehouseImageRequest = (params: {
  warehouseId: number | string;
  imageId: number | string;
}) => request.delete(apiRoutesMap.warehouseImage.getRoute(params));

export const uploadWarehousePrice = (data: FormData) =>
  request.post(apiRoutesMap.warehouseUploadPrice.getRoute(), data);
