import { Chip } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import Color from "color";
import React from "react";

import { OrderStatus } from "shared/api";

interface Props {
  status: OrderStatus;
}

export const OrderStatusLabel: React.FunctionComponent<Props> = ({
  status,
}) => {
  //@ts-ignore
  return (
    <Chip
      //@ts-ignore
      label={OrderStatus[status]}
      size="small"
      sx={{
        minWidth: "105px",
        //@ts-ignore
        backgroundColor: {
          completed: Color(grey[500]).alpha(0.4).string(),
          canceled: Color(red[500]).alpha(0.4).string(),
          confirmed: Color(orange[500]).alpha(0.4).string(),
          processing: Color(blue[500]).alpha(0.4).string(),
          assembled: Color(green[500]).alpha(0.4).string(),
        }[status],
      }}
    />
  );
};
