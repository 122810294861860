import {
  AddressUnit,
  ResponseWithMeta,
  useRequest,
  Warehouse,
} from "shared/api";
import { apiRoutesMap } from "shared/config";

export const useGetRegions = () => {
  const request = useRequest<ResponseWithMeta<Warehouse[]>>(
    apiRoutesMap.warehouses.getRoute()
  );

  return {
    ...request,
    data: Object.values(
      request?.data?.data?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr?.address?.region?.fias_id as string]: curr.address.region,
        }),
        {}
      ) || {}
    ) as AddressUnit[],
  };
};
