import { Box, Typography } from "@mui/material";
import { useGetOrder } from "entities/order";
import React from "react";
import { AssemblyReportPackage, RouteReport } from "shared/api";
import { useRouter } from "shared/hooks";
import {
  formatDate,
  formatDistance,
  differenceInHours,
} from "shared/libs/date";
import { getDisanceColor } from "../model";

const Dates = ({ from, to }: any) => (
  <Box display="flex">
    <Box display="flex">
      <Box>{formatDate(from)}</Box>
      <Box
        sx={{
          mx: 1,
        }}
      >
        {">"}
      </Box>
      <Box>{formatDate(to)}</Box>
    </Box>
  </Box>
);

const Route = ({ item }: { item?: RouteReport }) => {
  if (!item) return null;

  return (
    <Box display="flex" mt={2}>
      <Box
        sx={{
          backgroundColor: getDisanceColor(
            differenceInHours(item?.finish, item?.start)
          ),
          width: "16px",
          borderRadius: "8px",
          marginRight: "16px",
        }}
      />
      <Box>
        <Box>
          {item?.donor?.name} {" > "} {item?.recipient?.name}
        </Box>
        <Box>
          <Dates from={item?.start} to={item?.finish} />
          {item?.duration}, {item?.type}
        </Box>
        {item?.reports && (
          <Box>
            {item?.reports?.map((item) => (
              <Route key={item.donor?.id} item={item} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface Props {}

export const AssemblyReport: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const orderRequest = useGetOrder({
    variables: { id: router.params.orderId },
    skip: !router.params.orderId,
  });

  if (!orderRequest.data) return null;

  const report = orderRequest.data?.realization_method?.assembly_report;
  if(!report) return <div>Отчет отсутствует</div>

  return (
    <div>
      <Box>
        <Typography variant="h3" color="GrayText">
          {report?.assemblyPoint?.name}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4" color="GrayText">
          {formatDate(report?.requestTime)} {" > "}
          {formatDate(report?.estimatedAssemblyTime)},{" "}
          {formatDistance(report?.estimatedAssemblyTime, report?.requestTime)}
        </Typography>
      </Box>
      <Box>
        {report?.packages?.map((item) => (
          <Box mt={2} display="flex" key={item.donnor?.id}>
            <Box
              sx={{
                backgroundColor: getDisanceColor(
                  differenceInHours(
                    report?.estimatedAssemblyTime,
                    report?.requestTime
                  )
                ),
                width: "16px",
                borderRadius: "8px",
                marginRight: "16px",
              }}
            />
            <Box>
              <Box>{item?.donnor?.name}</Box>
              <Route item={item?.routeReport} />
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};
