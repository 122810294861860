import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";

const parseQueryItems = (queryObj: { [key: string]: string | string[] }) =>
  Object.entries(queryObj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: Array.isArray(curr[1]) ? curr[1] : curr[1]?.split(","),
    }),
    {}
  );

const stringifyQueryItems = (queryObj: { [key: string]: string | string[] }) =>
  Object.entries(queryObj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: Array.isArray(curr[1]) ? curr[1]?.join(",") : curr[1],
    }),
    {}
  );

export function useRouter() {
  const [query, setSearchParams] = useSearchParams();
  const location = useLocation();
  const push = useNavigate();
  const params = useParams();

  return useMemo(() => {
    return {
      push,
      pathname: location.pathname,
      location,
      query,
      params,
    };
  }, [query, location, params]);
}
