import {
  AccountCircleOutlined,
  CakeOutlined,
  CardGiftcardOutlined,
  CheckCircleOutlineRounded,
  CheckOutlined,
  EmailOutlined,
  PhoneOutlined,
  StarBorderOutlined,
  WcOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

//features
import { Orders } from "features/orders";

//entities
import { changeUser, useGetUser } from "entities/user";
import { useGetOrders } from "entities/order";

//shared
import { usePageTitle, useRouter } from "shared/hooks";
import { User as IUser } from "shared/api";
import { routesMap } from "shared/config";
import { formatPhone } from "shared/libs/string";
import { formatDate } from "shared/libs/date";
import { WithLoading } from "shared/ui";


interface Props {}

interface State {
  private_comment: string | null;
}

const User: React.FunctionComponent<{ user: IUser; onChange: () => any }> = ({
  user,
  onChange,
}) => {
  const [state, setState] = useState<State>({
    private_comment: null,
  });

  useEffect(() => {
    if (user) {
      setState({ private_comment: user.private_comment });
    }
  }, [user]);

  const handleChangeState = (value: State) => setState(value);

  const handleChangeUser = () =>
    changeUser({
      id: user?.id,
      private_comment: state.private_comment,
    }).then(onChange);

  if (!user) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <List>
          <ListItem divider title="ФИО">
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText primary={user?.full_name} />
          </ListItem>
          <ListItem divider title="Email">
            <ListItemIcon>
              <EmailOutlined />
            </ListItemIcon>
            <ListItemText primary={user?.email} />
            <ListItemSecondaryAction>
              <Box display="flex" alignItems="center">
                {user.is_email_confirmed && (
                  <div title="Подтвержден">
                    <CheckCircleOutlineRounded />
                  </div>
                )}
                {user.is_subscribed_email && (
                  <div title="Рассылка">
                    <CheckOutlined />
                  </div>
                )}
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disablePadding divider title="Телефон">
            <ListItemButton href={`tel:+7${user?.phone}`}>
              <ListItemIcon>
                <PhoneOutlined />
              </ListItemIcon>
              <ListItemText primary={formatPhone(`7${user.phone}`)} />
              <ListItemSecondaryAction>
                <Box display="flex" alignItems="center">
                  {user.is_subscribed_sms && (
                    <div title="Рассылка">
                      <CheckOutlined />
                    </div>
                  )}
                </Box>
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
          <ListItem title="Пол" divider>
            <ListItemIcon>
              <WcOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                {
                  true: "Мужской",
                  false: "Женский",
                  null: "Не указан",
                }[String(user?.gender)]
              }
            />
          </ListItem>
          <ListItem title="День рождения" divider>
            <ListItemIcon>
              <CakeOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                user?.birthday && formatDate(user?.birthday, "d MMMM yyyy")
              }
            />
          </ListItem>
          <ListItem title="Дисконтная карта" divider>
            <ListItemIcon>
              <CardGiftcardOutlined />
            </ListItemIcon>
            <ListItemText primary={user?.discount_card} />
          </ListItem>
          <ListItem title="Военный, пенсионер и т.д">
            <ListItemIcon>
              <StarBorderOutlined />
            </ListItemIcon>
            <ListItemText
              primary={[
                user?.military && "военный",
                user?.pensioner && "пенсионер",
              ]
                .filter((item) => item)
                ?.join(", ")}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          multiline
          minRows={4}
          fullWidth
          value={state.private_comment || ""}
          label="Комментарий о клиенте"
          onChange={(e) =>
            handleChangeState({ private_comment: e.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {user?.private_comment === state.private_comment && (
                  <CheckOutlined />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          height="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button onClick={handleChangeUser} variant="contained">
            Сохранить
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export const UserPage: React.FunctionComponent<Props> = ({}) => {
  const pageTitle = usePageTitle();

  const router = useRouter();

  const userRequest = useGetUser({
    variables: { id: router.params?.userId as string },
  });

  const user = userRequest?.data;

  useEffect(() => {
    if (user) {
      pageTitle.set(routesMap.user.getTitle(user));
    }
  }, [user]);

  const ordersRequest = useGetOrders({
    variables: { query: { "filter[user]": router.params?.userId as string } },
  });

  return (
    <Box>
      <WithLoading loading={userRequest.isLoading}>
        {user && (
          <Paper sx={{ p: 2 }}>
            <User user={user} onChange={userRequest.mutate} />
          </Paper>
        )}
      </WithLoading>
      <Paper sx={{ mt: 4 }}>
        <Orders
          withUser={false}
          orders={ordersRequest?.data?.data}
          totalCount={ordersRequest?.data?.total_count}
          loading={ordersRequest?.isLoading}
        />
      </Paper>
    </Box>
  );
};
