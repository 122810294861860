import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import ReactJson from "react-json-view";

import { Schedule } from "./Schedule";
import { useWarehouse } from "../model";

//features
import { AddressDialog } from "features/address-dialog";

//entities
import { uploadWarehousePrice } from "entities/warehouse";

//shared
import { usePageTitle, useRouter } from "shared/hooks";
import { routesMap } from "shared/config";
import { Warehouse } from "shared/api";

import { WithLoading } from "shared/ui";
import { Images } from "./Images";

const unchangableCheckboxProps: Array<{ key: keyof Warehouse; name: string }> =
  [
    {
      key: "is_selling",
      name: "Участвует в продажах",
    },
    {
      key: "is_pricing_participant",
      name: "Участвует в ценообразовнии",
    },
    {
      key: "is_assembly_point",
      name: "Участвует в комплектации",
    },
    {
      key: "is_online_payment",
      name: "Онлайн оплата",
    },
  ];

interface Props {}

export const WarehousePage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();
  const [result, setResult] = useState(null);

  const { warehouse, changedWarehouse, isLoading, change, refetch } =
    useWarehouse(router?.params?.warehouseId);

  usePageTitle(routesMap.warehouse.getTitle(warehouse));

  const setAttachedFile = (filesList: FileList | null) => {
    if (filesList && filesList[0]) {
      const form = new FormData();

      form.append(`supplier`, warehouse?.supplier.alias as string);
      form.append(`warehouse`, warehouse?.vendor_id as string);
      form.append(`price_list`, filesList[0]);

      uploadWarehousePrice(form).then(setResult);
    }
  };

  return (
    <WithLoading loading={isLoading}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">{warehouse?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={changedWarehouse?.name || ""}
            label="Название"
            onChange={(e) => change({ name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={changedWarehouse?.public_name || ""}
            label="Публичное название"
            onChange={(e) => change({ public_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressDialog onSelect={(address) => change({ address })}>
            {changedWarehouse?.address?.line}
          </AddressDialog>
        </Grid>
        <Grid item xs={12}>
          <List
            dense
            subheader={<ListSubheader component="div">Свойства</ListSubheader>}
          >
            {unchangableCheckboxProps.map((item) => (
              <ListItem key={item.key}>
                <ListItemIcon>
                  <Checkbox checked={Boolean(warehouse?.[item.key])} disabled />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
            <ListItemButton
              onClick={() =>
                change({
                  is_pick_point: !changedWarehouse?.is_pick_point,
                })
              }
            >
              <ListItemIcon>
                <Checkbox checked={Boolean(changedWarehouse?.is_pick_point)} />
              </ListItemIcon>
              <ListItemText primary="Пункт выдачи" />
            </ListItemButton>
          </List>
        </Grid>
        {changedWarehouse && (
          <>
            <Grid item xs={12}>
              <Schedule warehouse={changedWarehouse} afterSave={refetch} />
            </Grid>

            {warehouse?.sberbank_merchant && (
              <Grid item xs={12}>
                <List
                  dense
                  subheader={
                    <ListSubheader component="div">
                      Sberbank Merchant
                    </ListSubheader>
                  }
                >
                  <ListItem>
                    <ListItemText
                      primary={warehouse?.sberbank_merchant?.login}
                      secondary="login"
                    />
                  </ListItem>
                </List>
              </Grid>
            )}
            <Grid item xs={12}>
              <Images warehouse={changedWarehouse} afterSave={refetch} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Загрузка прайс-листа</Typography>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  disabled={Boolean(result)}
                >
                  {result ? "Файл загружен" : "Выберите файл"}

                  <input
                    type="file"
                    hidden
                    onChange={(event) => setAttachedFile(event.target.files)}
                  />
                </Button>
                <Typography>
                  *.xls Порядок столбцов: 'Штрихкод', 'Количество', 'Навазние',
                  'Цена'
                </Typography>

                {result && <ReactJson src={result} />}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </WithLoading>
  );
};
