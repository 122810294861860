import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  CssBaseline,
  IconButton,
} from "@mui/material";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { ThemeProvider as DarkModeProvider, useTheme } from "next-themes";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Close, CommentOutlined, StoreOutlined } from "@mui/icons-material";

//pages
import { UsersPage } from "pages/users";
import { UserPage } from "pages/user";
import { AssemblyReport } from "pages/assembly-report";
import { SuppliersPage } from "pages/suppliers";
import { CouriersPage } from "pages/couriers";
import { SupplierPage } from "pages/supplier";
import { WarehousesPage } from "pages/warehouses";
import { WarehousePage } from "pages/warehouse";

//entities
import { useGetMe } from "entities/user/api";

//shared
import { routesMap } from "shared/config";
import {
  AccessibleForwardOutlined,
  SupervisorAccountOutlined,
  ViewInArOutlined,
} from "shared/ui/icons";
import { LayoutMain } from "shared/ui";

const lightTheme = createTheme({});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: "rgb(231, 235, 240)",
      secondary: "rgb(178, 186, 194)",
      disabled: "rgba(255, 255, 255, 0.3)",
    },
    background: {
      default: "#0A1929",
      paper: "rgb(0, 30, 60)",
    },
    divider: "rgb(19, 47, 76)",
  },
});

const Theme = ({ children }: any) => {
  const { theme } = useTheme();
  return (
    <MuiThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      {children}
    </MuiThemeProvider>
  );
};

const routes = [
  {
    path: routesMap.users.path,
    getRoute: routesMap.users.getRoute,
    Component: UsersPage,
    Icon: SupervisorAccountOutlined,
    title: routesMap.users.getTitle(),
  },
  {
    path: routesMap.user.path,
    getRoute: routesMap.user.getRoute,
    Component: UserPage,
  },
  {
    path: routesMap.orderAssemblyReport.path,
    getRoute: routesMap.orderAssemblyReport.getRoute,
    Component: AssemblyReport,
  },
  {
    path: routesMap.suppliers.path,
    getRoute: routesMap.suppliers.getRoute,
    Icon: ViewInArOutlined,
    Component: SuppliersPage,
    title: routesMap.suppliers.getTitle(),
    routes: [
      {
        path: routesMap.supplier.path,
        getRoute: routesMap.supplier.getRoute,
        Component: SupplierPage,
      },
    ],
  },
  {
    path: routesMap.warehouses.path,
    getRoute: routesMap.warehouses.getRoute,
    Icon: StoreOutlined,
    Component: WarehousesPage,
    title: routesMap.warehouses.getTitle(),
    routes: [
      {
        path: routesMap.warehouse.path,
        getRoute: routesMap.warehouse.getRoute,
        Component: WarehousePage,
      },
    ],
  },
  {
    path: routesMap.couriers.path,
    getRoute: routesMap.couriers.getRoute,
    Icon: AccessibleForwardOutlined,
    Component: CouriersPage,
    title: routesMap.couriers.getTitle(),
  },
];

function SnackbarCloseButton({ snackbarKey }: any) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
}

export function App() {
  const meRequest = useGetMe();
  return (
    <BrowserRouter>
      <DarkModeProvider>
        <Theme>
          <CssBaseline />
          <SnackbarProvider
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <LayoutMain user={meRequest?.data} routes={routes}>
              <Routes>
                <Route index element={<div>Bonjour à tous!</div>} />
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.Component />}
                  >
                    {route?.routes?.map((nestedRoute) => (
                      <Route
                        key={nestedRoute.path}
                        path={nestedRoute.path}
                        element={<nestedRoute.Component />}
                      />
                    ))}
                  </Route>
                ))}
              </Routes>
            </LayoutMain>
          </SnackbarProvider>
        </Theme>
      </DarkModeProvider>
    </BrowserRouter>
  );
}
