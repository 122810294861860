import {
  Close,
  SearchOutlined,
  CheckOutlined,
  CheckCircleOutlineRounded,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";

//entities
import { useGetUsers } from "entities/user";

//shared
import { usePageTitle, useRouter } from "shared/hooks";
import { routesMap } from "shared/config";
import { User } from "shared/api";
import { formatPhone } from "shared/libs/string";
import { Table } from "shared/ui";

interface Props {}

export const UsersPage: React.FunctionComponent<Props> = ({}) => {
  usePageTitle(routesMap.users.getTitle());

  const router = useRouter();

  const [phone, setPhone] = useState("");

  const query = {
    limit: parseInt(router?.query?.get("limit") as string, 10) || 18,
    offset: parseInt(router?.query.get("offset") as string, 10) || 0,
    "filter[phone]": router?.query.get("filter[phone]") || "",
  };

  useEffect(() => {
    setPhone(query["filter[phone]"]);
  }, [query["filter[phone]"]]);

  const usersRequest = useGetUsers({ variables: { query } });

  const handleOnChange = (params: {
    page?: number;
    rowsPerPage?: number;
    "filter[phone]"?: string;
  }) => {
    const nextRequestQuery = {
      ...query,
      offset:
        params.page !== null && typeof params.page !== "undefined"
          ? (params.page * (params.rowsPerPage || query.limit)).toString()
          : query.offset?.toString(),
      limit: params?.rowsPerPage?.toString() || query.limit?.toString(),
      "filter[phone]": params["filter[phone]"] || "",
    };

    router.push({
      pathname: routesMap.users.path,
      search: createSearchParams(nextRequestQuery).toString(),
    });
  };

  return (
    <>
      <Box>
        <TextField
          label="Телефон"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter")
              handleOnChange({ "filter[phone]": phone, page: 0 });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {Boolean(phone) && (
                  <IconButton
                    onClick={(e) =>
                      handleOnChange({ "filter[phone]": "", page: 0 })
                    }
                  >
                    <Close />
                  </IconButton>
                )}

                <IconButton
                  disabled={!phone}
                  onClick={(e) =>
                    handleOnChange({ "filter[phone]": phone, page: 1 })
                  }
                >
                  <SearchOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Paper sx={{ mt: 4 }}>
        <Table
          limit={query?.limit || 0}
          offset={query?.offset || 0}
          totalCount={usersRequest?.data?.total_count || 0}
          onChange={handleOnChange}
          data={usersRequest?.data?.data || []}
          loading={usersRequest?.isLoading}
          headers={[
            {
              title: "ID",
              extract: (item: User) => (
                <Link href={routesMap.user.getRoute(item.id)} target="_blank">
                  {item.id}
                </Link>
              ),
            },
            {
              title: "ФИО",
              extract: (item: User) => item.full_name,
            },
            {
              title: "Телефон",
              extract: (item: User) => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {item.is_subscribed_sms && (
                    <span title="Рассылка">
                      <CheckOutlined sx={{ width: "16px", height: "16px" }} />
                    </span>
                  )}
                  {item.is_email_confirmed && (
                    <span title="Подтвержден">
                      <CheckCircleOutlineRounded
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </span>
                  )}
                  <Link href={`tel:+7${item.phone}`} noWrap>
                    {formatPhone(`7${item.phone}`)}
                  </Link>
                </Box>
              ),
              align: "right",
            },
            {
              title: "Email",
              extract: (item: User) => (
                <Box display="flex" alignItems="center">
                  {item.email}
                  {item.is_subscribed_email && (
                    <span title="Рассылка">
                      <CheckOutlined sx={{ width: "16px", height: "16px" }} />
                    </span>
                  )}
                </Box>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};
