import React, { PropsWithChildren } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link as MuiLink, LinkProps } from "@mui/material";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const Link: React.FunctionComponent<PropsWithChildren<LinkProps>> = ({
  children,
  ...prop
}) => {
  return (
    <MuiLink component={LinkBehavior} {...prop}>
      {children}
    </MuiLink>
  );
};
