import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import locale from "date-fns/locale/ru";
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { findSchedule } from "../model";

//entities
import { useGetRegions } from "entities/region";
import {
  addCourierSchedule,
  deleteSchedule,
  useGetCouriersSchedules,
} from "entities/schedule";

//shared
import { cn } from "shared/libs/css";
import { formatDate } from "shared/libs/date";
import { usePageTitle } from "shared/hooks";
import { routesMap } from "shared/config";

interface Props {}

export const CouriersPage: React.FunctionComponent<Props> = ({}) => {
  usePageTitle(routesMap.couriers.getTitle());

  const [regionFiasId, setRegionFiasId] = useState<null | string>(null);

  const regionsRequest = useGetRegions();

  useEffect(() => {
    if (!regionFiasId)
      setRegionFiasId(regionsRequest.data?.[0]?.fias_id || null);
  }, [regionsRequest.data]);

  const { data: schedules, ...couriersSchedulesRequest } =
    useGetCouriersSchedules();

  const handleChangeSchedule = (day: string | null) => {
    if (!regionFiasId || !day) return null;
    const schedule = findSchedule({
      day,
      schedules,
      regionFiasId,
    });
    if (schedule) {
      deleteSchedule(schedule.id).then(() => couriersSchedulesRequest.mutate());
    } else {
      addCourierSchedule({
        region: { fias_id: regionFiasId },
        day: formatDate(day, "yyyy-MM-dd'T'HH:mm:ss'+10:00'") as string,
      }).then(() => couriersSchedulesRequest.mutate());
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Typography variant="h5">Нерабочие дни</Typography>
            {regionsRequest.isLoading || couriersSchedulesRequest.isLoading ? (
              "Загрузка..."
            ) : (
              <>
                {Boolean(regionFiasId) && (
                  <Tabs
                    sx={{ mt: 2 }}
                    onChange={(e, value) => setRegionFiasId(value)}
                    value={regionFiasId}
                  >
                    {regionsRequest?.data?.map((region) => (
                      <Tab
                        key={region.fias_id}
                        label={`${region.name} ${region.type}`}
                        value={region.fias_id}
                      />
                    ))}
                  </Tabs>
                )}
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale}
                >
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value=""
                    onChange={handleChangeSchedule}
                    renderInput={(params) => <TextField {...params} />}
                    renderDay={(day, selectedDays, pickersDayProps) => (
                      <PickersDay
                        className={cn(
                          findSchedule({ day, schedules, regionFiasId }) &&
                            "Mui-selected"
                        )}
                        {...pickersDayProps}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
