import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useDebounce } from "shared/hooks";
import {
  suggestionsAddressesRequest,
  cleanAddressRequest,
  convertAddress,
} from "entities/address";
import { Address } from "shared/api";

interface Props {
  onSelect: (address: Address) => any;
}

export const AddressDialog: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [suggestedAddresses, setAdresses] = useState<any>(null);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce<string>(query, 500);

  useEffect(() => {
    if (debouncedQuery.length > 3) {
      setIsFetching(true);
      suggestionsAddressesRequest(debouncedQuery).then((data: any) => {
        setAdresses(data);
        setIsFetching(false);
      });
    }
  }, [debouncedQuery]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSelect = async (item: any) => {
    await cleanAddressRequest(item.value).then((data) =>
      onSelect(convertAddress(data))
    );
    handleClose();
  };

  return (
    <>
      {children}
      <IconButton color="primary" onClick={handleOpen} size="small">
        <Edit />
      </IconButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          <TextField
            fullWidth
            autoFocus
            id="standard-name"
            label="Адрес"
            margin="none"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <List>
            {isFetching && !suggestedAddresses && (
              <ListItem>
                <CircularProgress size={24} />
              </ListItem>
            )}
            {suggestedAddresses?.map((item: any) => {
              if (!item.data.house) return null;
              return (
                <ListItemButton
                  key={item.data.fias_id}
                  onClick={() => handleSelect(item)}
                  divider
                >
                  <ListItemText primary={item.value} />
                </ListItemButton>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
