import { Schedule } from "shared/api";
import { formatDate } from "shared/libs/date";

export const findSchedule = ({
  day,
  schedules,
  regionFiasId,
}: {
  day: string;
  schedules?: Schedule[];
  regionFiasId: string | null;
}) =>
  schedules
    ?.filter((schedule) => schedule?.region?.fias_id === regionFiasId)
    ?.find(
      (item) =>
        formatDate(item.day, "yyyy-MM-dd") === formatDate(day, "yyyy-MM-dd")
    );
