import {
  request,
  RequestHookParams,
  Schedule,
  ScheduleType,
  Supplier,
  useRequest,
} from "shared/api";
import { apiRoutesMap } from "shared/config";

export const useGetSchedule = () =>
  useRequest<Schedule[]>(apiRoutesMap.schedules.getRoute());

export const useGetCouriersSchedules = () => {
  const schedulesRequest = useRequest<Schedule[]>(
    apiRoutesMap.schedules.getRoute()
  );

  return {
    ...schedulesRequest,
    data: schedulesRequest?.data?.filter(
      (schedule) => schedule?.type === ScheduleType.courier
    ),
  };
};

export const useGetSupplierSchedule = ({
  variables,
  ...params
}: RequestHookParams<{ alias?: string | number }>) => {
  const schedulesRequest = useGetSchedule();
  return {
    ...schedulesRequest,
    data: schedulesRequest?.data?.filter(
      (schedule) => schedule?.supplier?.alias === variables?.alias
    ),
  };
};

export const addSupplierSchedule = (data: {
  supplier: Supplier;
  day: string;
}) =>
  request.post(apiRoutesMap.schedules.getRoute(), {
    type: ScheduleType.supplier,
    ...data,
  });

export const addCourierSchedule = (data: {
  region: { fias_id: string };
  day: string;
}) =>
  request.post(apiRoutesMap.schedules.getRoute(), {
    type: ScheduleType.courier,
    ...data,
  });

export const deleteSchedule = (id: number | string) =>
  request.delete(apiRoutesMap.schedule.getRoute({ id }));
