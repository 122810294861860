import { changeWarehouseRequest, useGetWarehouse } from "entities/warehouse";
import { useEffect, useState } from "react";
import { Warehouse } from "shared/api";
import { useDebounce } from "shared/hooks";

export const useWarehouse = (id?: number | string) => {
  const [changedWarehouse, setWarehouse] = useState<null | Warehouse>(null);

  const warehouseRequest = useGetWarehouse({
    variables: { id },
  });

  const debouncedChangedWarehouse = useDebounce(changedWarehouse, 500);

  const save = () => {
    if (debouncedChangedWarehouse) {
      changeWarehouseRequest(debouncedChangedWarehouse).then(() => {
        warehouseRequest.mutate().then(() => setWarehouse(null));
      });
    }
  };

  useEffect(save, [debouncedChangedWarehouse]);

  const change = (data: Partial<Warehouse>) => {
    if (warehouseRequest.data) {
      setWarehouse({ ...warehouseRequest.data, ...data });
    }
  };

  return {
    change,
    warehouse: warehouseRequest.data,
    changedWarehouse: changedWarehouse || warehouseRequest.data,
    isLoading: warehouseRequest.isValidating,
    refetch: warehouseRequest.mutate,
  };
};
