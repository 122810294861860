import { RequestHookParams, Supplier, useRequest } from "shared/api";
import { apiRoutesMap } from "shared/config";

export const useGetSuppliers = () =>
useRequest<Supplier[]>(apiRoutesMap.suppliers.getRoute());


export const useGetSupplier = ({
    variables,
    ...params
  }: RequestHookParams<{ alias?: string | number }>) => {
    const suppliersRequest = useGetSuppliers()
    return {
        ...suppliersRequest,
        data: suppliersRequest?.data?.find(supplier => supplier.alias === variables?.alias)
    }
  }
