import {
  useRequest,
  RequestHookParams,
  Order,
  ListRequestVariables,
  ResponseWithMeta,
} from "shared/api";
import { apiRoutesMap } from "shared/config";

export const useGetOrder = ({
  variables,
  ...params
}: RequestHookParams<{ id?: string | number }>) =>
  useRequest<Order>(apiRoutesMap.order.getRoute({ id: variables?.id }), params);

export const useGetOrders = ({
  variables = {},
  ...params
}: RequestHookParams<
  ListRequestVariables<{ "filter[user]"?: number | string | null }>
>) =>
  useRequest<ResponseWithMeta<Order[]>>(
    apiRoutesMap.orders.getRoute(variables),
    params
  );
