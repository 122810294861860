import React from "react";
import { ListItem, List, ListItemText, Grid } from "@mui/material";

import { useGetSuppliers } from "entities/supplier";
import { Link } from "shared/ui";
import { usePageTitle } from "shared/hooks";
import { routesMap } from "shared/config";
import { Outlet } from "react-router-dom";

interface Props {}

export const SuppliersPage: React.FunctionComponent<Props> = ({}) => {
  usePageTitle(routesMap.suppliers.getTitle());
  
  const suppliersRequest = useGetSuppliers();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <List>
          {suppliersRequest?.data
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            ?.map((supplier) => (
              <ListItem key={supplier.alias} divider>
                <ListItemText
                  primary={
                    <Link href={routesMap.supplier.getRoute(supplier.alias)}>
                      {supplier.name}
                    </Link>
                  }
                  secondary={supplier.alias}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
      <Grid item xs={12} md={8}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
