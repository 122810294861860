import React, { useEffect } from "react";
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/ru";
import { Box } from "@mui/system";

//entities
import {
  addSupplierSchedule,
  deleteSchedule,
  useGetSupplierSchedule,
} from "entities/schedule";
import { useGetSupplier } from "entities/supplier";

//shared
import { usePageTitle, useRouter } from "shared/hooks";
import { cn } from "shared/libs/css";
import { formatDate } from "shared/libs/date";
import { routesMap } from "shared/config";

interface Props {}

export const SupplierPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const pageTitle = usePageTitle();

  const supplierRequest = useGetSupplier({
    variables: { alias: router?.params?.supplierAlias },
  });

  useEffect(() => {
    if (supplierRequest.data) {
      pageTitle.set(routesMap.supplier.getTitle(supplierRequest.data));
    }
  }, [supplierRequest.data]);

  const supplierScheduleRequest = useGetSupplierSchedule({
    variables: { alias: router?.params?.supplierAlias },
  });

  const findSchedule = (day: string) =>
    supplierScheduleRequest.data?.find(
      (item) =>
        formatDate(item.day, "yyyy-MM-dd") === formatDate(day, "yyyy-MM-dd")
    );

  const handleChangeSchedule = (day: string | null) => {
    if (!supplierRequest.data || !day) return null;

    const schedule = findSchedule(day);

    if (schedule) {
      deleteSchedule(schedule.id).then(() => supplierScheduleRequest.mutate());
    } else {
      addSupplierSchedule({
        supplier: supplierRequest.data,
        day: formatDate(day, "yyyy-MM-dd'T'HH:mm:ss'+10:00'") as string,
      }).then(() => supplierScheduleRequest.mutate());
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">{supplierRequest.data?.name}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <Box p={2}>
            <Typography variant="h5">Нерабочие дни</Typography>
            {supplierScheduleRequest.isLoading ? (
              "Загрузка..."
            ) : (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value=""
                  onChange={(newValue) => handleChangeSchedule(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  renderDay={(day, selectedDays, pickersDayProps) => (
                    <PickersDay
                      className={cn(findSchedule(day) && "Mui-selected")}
                      {...pickersDayProps}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
