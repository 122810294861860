import { Supplier, addQueryParams, User, Order, Warehouse } from "shared/api";
import { formatDate } from "shared/libs/date";

export const DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN;
const PRODUCT_HOST = process.env.REACT_APP_PRODUCT_HOST;
const APP_API_HOST = process.env.REACT_APP_API_HOST;

interface GetRouteParams {
  id?: number | string;
  query?: { [key: string | number]: string | number | null };
}

const publicApi = (url: string) => `${APP_API_HOST}/public_v1${url}`;
const privateApi = (url: string) => `${APP_API_HOST}/v1${url}`;

const getProductUrl = (url: string) => `${PRODUCT_HOST}${url}`;

export const apiRoutesMap = {
  userMe: {
    getRoute: () => publicApi("/me"),
  },
  user: {
    getRoute: (params: GetRouteParams) => privateApi(`/users/${params.id}`),
  },
  users: {
    getRoute: ({ query }: GetRouteParams) =>
      privateApi(addQueryParams({ href: "/users", query })),
  },
  orders: {
    getRoute: ({ query }: GetRouteParams) =>
      privateApi(addQueryParams({ href: "/orders", query })),
  },
  order: {
    getRoute: (params: GetRouteParams) => privateApi(`/orders/${params.id}`),
  },
  schedules: {
    getRoute: () => privateApi(`/schedule_weekend_day`),
  },
  schedule: {
    getRoute: (params: GetRouteParams) =>
      privateApi(`/schedule_weekend_day/${params.id}`),
  },
  suppliers: {
    getRoute: () => privateApi("/suppliers"),
  },
  warehouses: {
    getRoute: () => privateApi("/warehouses"),
  },
  warehouseImageTypes: {
    getRoute: () => privateApi("/warehouse-image-types"),
  },
  warehouse: {
    getRoute: (params: GetRouteParams) =>
      privateApi(`/warehouses/${params.id}`),
  },
  warehouseSchedule: {
    getRoute: (params: GetRouteParams) =>
      privateApi(`/warehouses/${params.id}/schedule`),
  },
  warehouseImages: {
    getRoute: (params: GetRouteParams) =>
      privateApi(`/warehouses/${params.id}/images`),
  },
  warehouseImage: {
    getRoute: (
      params: GetRouteParams & {
        warehouseId: string | number;
        imageId: string | number;
      }
    ) =>
      privateApi(`/warehouses/${params.warehouseId}/images/${params.imageId}`),
  },
  warehouseUploadPrice: {
    getRoute: () => privateApi("/import_warehouse_xls_price"),
  },
  reviews: {
    getRoute: ({ query }: GetRouteParams) =>
      privateApi(addQueryParams({ href: "/reviews", query })),
  },
};

const getAppPrefix = (url: string) => url;

export const routesMap = {
  users: {
    path: getAppPrefix("/users"),
    getTitle: () => "Клиенты",
    getRoute: () => getAppPrefix("/users"),
  },
  user: {
    path: getAppPrefix("/users/:userId"),
    getTitle: (user: User) => user?.full_name || user?.email || user.phone,
    getRoute: (id?: string | number) => getAppPrefix(`/users/${id}`),
  },
  order: {
    path: "/shop/order/:orderId",
    getTitle: (order: Order) =>
      `Заказ ${order.id} от ${formatDate(order.created_at)}`,
    getRoute: (id?: string | number) =>
      `https://control-panel.bonjour-dv.ru/shop/order/${id}`,
  },
  orderAssemblyReport: {
    path: getAppPrefix("/orders/:orderId/assembly-report"),
    getTitle: () => null,
    getRoute: (id?: string | number) =>
      getAppPrefix(`/orders/${id}/assembly-report`),
  },
  schedule: {
    path: getAppPrefix("/schedule"),
    getTitle: () => "Расписания",
    getRoute: () => getAppPrefix("/schedule"),
  },
  suppliers: {
    path: getAppPrefix("/suppliers"),
    getTitle: () => "Поставщики",
    getRoute: () => getAppPrefix("/suppliers"),
  },
  supplier: {
    path: getAppPrefix("/suppliers/:supplierAlias"),
    getTitle: (supplier?: Supplier) => supplier?.name || supplier?.alias,
    getRoute: (alias?: string | number) => getAppPrefix(`/suppliers/${alias}`),
  },
  warehouses: {
    path: getAppPrefix("/warehouses"),
    getTitle: () => "Склады",
    getRoute: () => getAppPrefix("/warehouses"),
  },
  warehouse: {
    path: getAppPrefix("/warehouses/:warehouseId"),
    getTitle: (warehouse?: Warehouse | null) => warehouse?.name,
    getRoute: (id?: string | number) => getAppPrefix(`/warehouses/${id}`),
  },
  couriers: {
    path: getAppPrefix("/couriers"),
    getTitle: () => "Курьеры",
    getRoute: () => getAppPrefix("/couriers"),
  },
};

export const productRoutesMap = {
  product: {
    getRoute: (id?: string | number) => getProductUrl(`/products/${id}`),
  },
};
