import { Warehouse, WarehouseImage } from "shared/api";

export const warehousePropsNames: Array<{
  key: keyof Warehouse;
  name: string;
}> = [
  { key: "is_selling", name: "Участвует в продажах" },
  { key: "is_pricing_participant", name: "Участвует в ценообразовнии" },
  { key: "is_assembly_point", name: "Участвует в комплектации" },
  { key: "is_pick_point", name: "Самовывоз" },
  { key: "is_online_payment", name: "Онлайн оплата" },
];

export const checkIfAllRequiredImagesExist = (
  types?: string[],
  warehouse?: Warehouse
) =>
  types
    ?.filter((item) => item !== "accessibility")
    .every((type) =>
      warehouse?.warehouse_images?.find((image) => image.type === type)
    );
