import { Box, CircularProgress, Fade } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

interface Props {
  loading: boolean;
}

export const WithLoading: FC<PropsWithChildren<Props>> = ({
  loading,
  children,
}) => {
  return (
    <Box position="relative">
      <Fade in={!loading} timeout={{ enter: 300, exit: 800 }}>
        <div>{children}</div>
      </Fade>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "800ms" : "0ms",
        }}
        unmountOnExit
      >
        <div>
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        </div>
      </Fade>
    </Box>
  );
};
