// @flow
import React, { useState, useEffect } from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Dialog,
  Grid,
  ListItemButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { changeWarehouseScheduleRequest } from "entities/warehouse/api";
import { Warehouse } from "shared/api";

interface Props {
  warehouse: Warehouse;
  afterSave: any;
}

const dayseNames = {
  workday: "понедельник-пятница",
  saturday: "суббота",
  sunday: "воскресенье",
};

export const Schedule: React.FunctionComponent<Props> = ({
  warehouse,
  afterSave,
}) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [localSchedule, setSchedule] = useState<any>(null);
  const [start_minute, setStartMinute] = useState(null);
  const [end_hour, setEndHour] = useState(null);
  const [end_minute, setEndMinute] = useState(null);

  const schedule = warehouse.warehouse_schedule;

  const handleSetShcnedule = (data: any) =>
    setSchedule((state: any) => ({ ...state, ...data }));

  useEffect(() => {
    setSchedule(schedule);
  }, [schedule]);

  const handleOnSave = () => {
    changeWarehouseScheduleRequest(warehouse.id, localSchedule).then(() => {
      afterSave();
      setDialog(false);
    });
  };

  const renderString = (scheduleItem: any) => {
    if (
      Object.keys(scheduleItem).length &&
      Object.keys(scheduleItem).every(
        (key) => typeof scheduleItem[key] === "number"
      )
    ) {
      return `с ${scheduleItem.start_hour}:${
        scheduleItem.start_hour < 10
          ? `0${scheduleItem.start_minute}`
          : scheduleItem.start_minute
      } до ${scheduleItem.end_hour}:${
        scheduleItem.end_minute < 10
          ? `0${scheduleItem.end_minute}`
          : scheduleItem.end_minute
      }`;
    }

    return "выходной";
  };

  if (!localSchedule) return null;

  const renderSelect = ({ value, onChange, elementsCount }: any) => (
    <Select
      value={value}
      onChange={(e: any) => onChange(e.target.value)}
      renderValue={(value: any) => (value < 10 ? `0${value}` : value)}
    >
      {new Array(elementsCount).fill(0).map((item, index) => (
        <MenuItem key={index} value={item + index}>
          {item + index < 10 ? `0${item + index}` : item + index}
        </MenuItem>
      ))}
    </Select>
  );

  const renderDayEditRow = (key: any) => (
    <Grid item xs={12}>
      <Box>
        <Box>
          {
            //@ts-ignore
            dayseNames[key]
          }
        </Box>
        <Box display="flex">
          <Box>
            {renderSelect({
              value: localSchedule[key].start_hour,
              onChange: (start_hour: any) =>
                handleSetShcnedule({
                  [key]: { ...localSchedule[key], start_hour },
                }),
              elementsCount: 24,
            })}
            :{" "}
            {renderSelect({
              value: localSchedule[key].start_minute,
              onChange: (start_minute: any) =>
                handleSetShcnedule({
                  [key]: { ...localSchedule[key], start_minute },
                }),
              elementsCount: 60,
            })}
          </Box>
          <Box ml={4}>
            {renderSelect({
              value: localSchedule[key].end_hour,
              onChange: (end_hour: any) =>
                handleSetShcnedule({
                  [key]: { ...localSchedule[key], end_hour },
                }),
              elementsCount: 24,
            })}
            :{" "}
            {renderSelect({
              value: localSchedule[key].end_minute,
              onChange: (end_minute: any) =>
                handleSetShcnedule({
                  [key]: { ...localSchedule[key], end_minute },
                }),
              elementsCount: 60,
            })}
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <React.Fragment>
      <List
        dense
        subheader={<ListSubheader component="div">Расписание</ListSubheader>}
      >
        <ListItemButton onClick={() => setDialog(!dialog)}>
          <ListItemText
            primary={renderString(schedule.workday)}
            secondary={dayseNames.workday}
          />
        </ListItemButton>
        <ListItemButton onClick={() => setDialog(!dialog)}>
          <ListItemText
            primary={renderString(schedule.saturday)}
            secondary={dayseNames.saturday}
          />
        </ListItemButton>
        <ListItemButton onClick={() => setDialog(!dialog)}>
          <ListItemText
            primary={renderString(schedule.sunday)}
            secondary={dayseNames.sunday}
          />
        </ListItemButton>
      </List>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogContent>
          <Grid container spacing={4}>
            {renderDayEditRow("workday")}
            {renderDayEditRow("saturday")}
            {renderDayEditRow("sunday")}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <Button variant="contained" color="primary" onClick={handleOnSave}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
