enum Roles {
  "ROLE_SUPREME_LORD",
}

export interface User {
  id: number;
  email: string | null;
  first_name: string | null;
  father_name: string | null;
  last_name: string | null;
  birthday: string | null;
  gender: boolean;
  discount_card: string | null;
  military: boolean;
  pensioner: boolean;
  is_subscribed_email: boolean;
  is_subscribed_sms: boolean;
  roles: Roles[];
  phone: string;
  full_name: string | null;
  private_comment: string | null;
  is_email_confirmed: boolean;
}

export interface AddressUnit {
  fias_id: string;
  type: string;
  short_type: string;
  name: string;
}

export interface Address {
  line: string;
  locality?: AddressUnit | null;
  id?: number;
  region: AddressUnit | null;
  coordinates: {};
  postcode: number;
  area: AddressUnit | null;
  city: AddressUnit | null;
  settlement: AddressUnit | null;
  street: AddressUnit | null;
  house: string | null;
  flat: string | null;
}

export enum RealizationMethodType {
  "pick_up" = "Самовывоз",
  "courier" = "Доставка",
}

export interface SberbankMerchant {
  login: string;
}

export interface WarehouseImage {
  width: number;
  height: number;
  origin_path: string;
  created_at: string;
  id: number;
  type: string;
}

export interface Warehouse {
  id: number;
  name: string;
  vendor_id: string;
  address: Address;
  is_selling?: boolean;
  is_pricing_participant?: boolean;
  is_assembly_point?: boolean;
  is_pick_point?: boolean;
  is_online_payment?: boolean;
  sberbank_merchant?: SberbankMerchant | null;
  warehouse_schedule: WarehouseSchedule;
  warehouse_images: WarehouseImage[];
  supplier: Supplier;
  public_name: string | null
}
export interface WarehouseScheduleItem {
  end_hour: number;
  end_minute: number;
  start_hour: number;
  start_minute: number;
}
export interface WarehouseSchedule {
  saturday: WarehouseScheduleItem;
  sunday: WarehouseScheduleItem;
  workday: WarehouseScheduleItem;
}

export interface Payment {
  type: string;
}

export interface AssemblyReportPackageElement {
  id: number;
  barcodes: string;
  title: string;
  quantity: number;
}

export interface RouteReport {
  duration?: string;
  type?: string;
  start?: string;
  finish?: string;
  donor?: Warehouse;
  recipient?: Warehouse;
  reports?: RouteReport[];
}

export interface AssemblyReportPackage {
  donnor?: Warehouse;
  routeReport?: RouteReport;
  elements?: AssemblyReportPackageElement[];
}

export interface AssemblyReport {
  requestTime?: string; //Время начала сборки
  estimatedAssemblyTime?: string; // Прогнозируемое время сборки
  assemblyPoint?: Warehouse;
  packages?: AssemblyReportPackage[];
}

export interface RealizationMethod {
  type: RealizationMethodType;
  estimated_assembly_time: string | null;
  payments: Payment[];
  address?: Address;
  pick_up_point?: Warehouse;
  recipient_phone: string;
  assembly_report?: AssemblyReport;
}

export enum OrderStatus {
  "completed" = "Выполнен",
  "canceled" = "Отменен",
  "confirmed" = "Подтвержден",
  "processing" = "В обработке",
  "assembled" = "Собран",
}

export interface Order {
  id: number;
  state: OrderStatus;
  user: User;
  realization_method?: RealizationMethod;
  cost: number;
  liked: boolean | null;
  created_at: string;
  updated_at: string;
}

export interface Supplier {
  alias: string;
  name: string;
}

export enum ScheduleType {
  courier = "courier",
  supplier = "supplier",
}

export interface Schedule {
  id: number;
  day: string;
  region?: AddressUnit;
  supplier?: Supplier;
  type: ScheduleType;
}

export interface Review {
  id: number;
  updated_at: string;
  created_at: string;
  rating: number;
  comment: string;
  product: Product;
  enabled: boolean;
  user: User;
}

export interface Product {
  id: number;
  title: string;
}
