import { request } from "shared/api";
import { DADATA_TOKEN } from "shared/config";

const options = {
  withCredentials: false,
  headers: {
    Authorization: `Token ${DADATA_TOKEN}`,
  },
};

export const suggestionsAddressesRequest = (query: string) =>
  request.post<any>(
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    { query, count: 8 },
    options
  ).then((data) => data?.suggestions);;

export const cleanAddressRequest = (query: string) =>
  request
    .post<any>(
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
      { query, count: 1 },
      options
    )
    .then((data) => data?.suggestions[0]);
