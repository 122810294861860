import React, { useState } from "react";

import { Outlet } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Image } from "@mui/icons-material";

//entities
import {
  checkIfAllRequiredImagesExist,
  useGetWarehouses,
  useGetWarehousImageTypes,
  warehousePropsNames,
} from "entities/warehouse";

//shared
import { routesMap } from "shared/config";
import { Link } from "shared/ui";
import { usePageTitle } from "shared/hooks";

interface Props {}

export const WarehousesPage: React.FunctionComponent<Props> = ({}) => {
  usePageTitle(routesMap.warehouses.getTitle());

  const [state, setState] = useState<{ [key: string]: boolean | null }>(
    warehousePropsNames.reduce(
      (prev, curr) => ({ ...prev, [curr.key]: null }),
      {}
    )
  );

  const warehousesRequest = useGetWarehouses();

  const { data: types } = useGetWarehousImageTypes();

  const warehouses = warehousesRequest.data?.data?.filter((warehouse) => {
    const actual = warehousePropsNames.reduce(
      (prev, curr) => `${prev}${String(warehouse[curr.key])}`,
      ""
    );
    const requested = warehousePropsNames.reduce(
      (prev, curr) =>
        `${prev}${
          state[curr.key] === null
            ? String(warehouse[curr.key])
            : String(state[curr.key])
        }`,
      ""
    );

    return actual === requested;
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={5}>
        <Box>
          {warehousePropsNames.map((item) => (
            <FormControlLabel
              key={item.key}
              control={
                <Checkbox
                  checked={Boolean(state[item.key])}
                  indeterminate={state[item.key] === false}
                  onChange={() =>
                    setState({
                      ...state,
                      [item.key]:
                        { null: true, true: false, false: null }[
                          String(state[item.key])
                        ] ?? null,
                    })
                  }
                />
              }
              label={item.name}
            />
          ))}
        </Box>
        <List>
          {warehouses
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            ?.map((warehouse) => (
              <ListItem key={warehouse.name} divider>
                <ListItemText
                  primary={
                    <Box>
                      <Box>

                    <Link
                      href={routesMap.warehouse.getRoute(warehouse.id)}
                      onClick={() => window.scrollTo(0, 0)}
                      >
                      {warehouse.name}
                    </Link>
                        </Box>
                        <Box>{warehouse.public_name}</Box>
                      </Box>
                  }
                  secondary={warehouse.address?.line}
                />
                <ListItemSecondaryAction>
                  {!checkIfAllRequiredImagesExist(types, warehouse) && (
                    <Tooltip title="Недостаточно фотографий">
                      <Icon color="error">
                        <Image />
                      </Icon>
                    </Tooltip>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Grid>
      <Grid item xs={12} md={7}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
