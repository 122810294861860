import {
  ListRequestVariables,
  request,
  RequestHookParams,
  ResponseWithMeta,
  User,
  useRequest,
} from "shared/api";
import { apiRoutesMap } from "shared/config";

export const useGetMe = () => useRequest<User>(apiRoutesMap.userMe.getRoute());

export const useGetUser = ({
  variables,
  ...params
}: RequestHookParams<{ id?: string | number }>) =>
  useRequest<User>(apiRoutesMap.user.getRoute({ id: variables?.id || "" }), {
    ...params,
    skip: params?.skip || !variables?.id,
  });

export const useGetUsers = ({
  variables = {},
  ...params
}: RequestHookParams<
  ListRequestVariables<{ "filter[phone]"?: number | string | null }>
>) =>
  useRequest<ResponseWithMeta<User[]>>(apiRoutesMap.users.getRoute(variables), {
    ...params,
  });

export const changeUser = ({ id, ...data }: Partial<User>) =>
  request.patch(apiRoutesMap.user.getRoute({ id }), data);
